import React from "react";
import {IContainerProps} from "./Container";
import useIsMobile from "../../helpers/useIsMobile";
import {Card} from "./Card";
import {Colors} from "../colors";

interface IMapColorToTheme {
  color: "purple" | "yellow" | "red" | "green" | "black";
}

interface INotificationCard extends IContainerProps, IMapColorToTheme {}

const mapColorToTheme = {
  purple: Colors.secondary._100,
  yellow: Colors.orange._100,
  red: Colors.red._100,
  green: Colors.primary._100,
  black: Colors.grey._1000,
};

export const NotificationCard = ({color, children, sx}: INotificationCard) => {
  const isMobile = useIsMobile();
  return (
    <Card
      sx={{
        boxShadow: "none",
        backgroundColor: mapColorToTheme[color],
        gap: "12px",
        width: isMobile ? "100%" : "550px",
        padding: "12px",
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
