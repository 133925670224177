import {axiosConsumerAppInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {ContributionLinkResponse} from "../../../declerations/server/payment_plan_models";

type Variables = {
  accountId: string;
  isAgent: boolean;
};

type Data = ContributionLinkResponse;

export const getGuestContributionLink = async (variables: Variables): Promise<Data> => {
  const result = await axiosConsumerAppInstance.get<Data, AxiosResponse<Data>>(
    `/guest-payment-url/${variables.accountId}`
  );
  return result.data;
};
